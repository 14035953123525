import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { GetMethods, Methods, PutMethods, getStatusType, transformMediumDate } from '@methods/methods';
import { User } from '@models/auth-model';
import { ApiService } from '@services/api.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { Filters, RelevanceFilter, StatusFilter } from '@methods/constants';
import { Subscription } from 'rxjs';
import { MediaResponse, MediaService } from '@services/media.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BrandSetup, BrandSite, ConfigByDomain, OpenTicketDTO, ResponseData } from '@models/models';
import { ThemeService } from '@services/theme.service';
import { NotificationService } from '@services/notification.service';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-ticket-general',
  templateUrl: './ticket-general.component.html'
})
export class TicketGeneralComponent implements OnInit, OnDestroy {
  mediaSubscription: Subscription;
  Media: MediaResponse;
  exportColumns!: any[];
  columsData!: any;
  statusFilter: Filters[];
  relevanceFilter: Filters[];
  payloadData!: any;
  loading: boolean = true;
  userLogin: User;
  domainInfo: ConfigByDomain;
  allSiteTags: Filters[];
  subjectList: any;
  departmentList: any;
  statusList: any;
  relevanceList: Filters[];
  shortCutByDepartment: Filters[];
  allUserList: Filters[];
  visible: boolean = false;
  tabSelected: number = 0;
  infoData: any = [];
  userActive: string = "";
  messageResult: string = "";
  openTicketStatus: boolean = false;
  step: number = 1;
  ticketSelected: any;
  showMessageInfo: boolean = false;
  isDarkTheme: boolean;
  @Input() formValidation: any;
  @Input() ticketCenterType: number;
  @Input() formType: number;
  @Input() title: any;
  @Input() firstTab: any;
  @Input() secondTab: any;
  @Input() thirdTab: any;


  showHardReloadBtn:boolean = false;


  @ViewChild('dt') table: Table;

  _BrandSetup: BrandSetup = new BrandSetup();
  _ExistsBrandSetup: boolean = false;
  _listBrandSites: BrandSite[] = [];
  _refreshData: boolean = true;
  _interval: any;
  _isActiveInterval: boolean = false;

  constructor(private _apiService: ApiService,private _loadingService: LoadingService, private primengConfig: PrimeNGConfig, private _mediaService: MediaService, private _translateService: TranslateService, private _themeService: ThemeService, private _notificationService: NotificationService) {

    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  ngOnInit() {

    this.statusFilter = StatusFilter;
    this._refreshData = true;
    this.relevanceFilter = RelevanceFilter;

    this.columsData = [
    { field: 'ticket', header: 'Ticket', title: 'HEADER_REPORT.TICKET', customExportHeader: 'Product Code' },
    { field: 'account', header: 'Account', title: 'HEADER_REPORT.ACCOUNT' },
    { field: 'site', header: 'Website', title: 'HEADER_REPORT.WEBSITE' },
    { field: 'department', header: 'Department', title: 'HEADER_REPORT.DEPARTMENT' },
    { field: 'field', header: 'Field', title: 'HEADER_REPORT.FIELD' },
    { field: 'priority', header: 'Priority', title: 'HEADER_REPORT.PRIORITY' },
    //   { field: 'status', header: 'Status', title: 'HEADER_REPORT.STATUS' },
    { field: 'assigned', header: 'Assigned', title: 'HEADER_REPORT.ASSIGNED' },
    { field: 'date', header: 'Date', title: 'HEADER_REPORT.DATE' }
    ];

    this.exportColumns = this.columsData.slice(0, -1).map((col:any) => ({
    title: this.TranslateText(col.title),
    dataKey: col.field
    }));

    this.exportColumns.push({
        title: 'HEADER_REPORT.STATUS',
        dataKey: 'status'
    })

    this.primengConfig.ripple = true;
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.domainInfo = JSON.parse(localStorage.getItem('DomainCTS')!);
    this._BrandSetup = JSON.parse(sessionStorage.getItem('BrandSetup')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;



    if(this._BrandSetup != null){
        if(this.domainInfo.idSite == 992){
            this.columsData = [
                { field: 'ticket', header: 'Ticket', title: 'HEADER_REPORT.TICKET', customExportHeader: 'Product Code' },
                { field: 'account', header: 'Account', title: 'HEADER_REPORT.ACCOUNT' },
                { field: 'department', header: 'Department', title: 'HEADER_REPORT.DEPARTMENT' },
                { field: 'subject', header: 'Subject', title: 'HEADER_REPORT.SUBJECT' },
                { field: 'field', header: 'Field', title: 'HEADER_REPORT.FIELD' },
                { field: 'priority', header: 'Priority', title: 'HEADER_REPORT.PRIORITY' },
                { field: 'status', header: 'Status', title: 'HEADER_REPORT.STATUS' },
                { field: 'assigned', header: 'Assigned', title: 'HEADER_REPORT.ASSIGNED' },
                { field: 'date', header: 'Date', title: 'HEADER_REPORT.DATE' }
                ];
        }
        this.GetBrandSites(this._BrandSetup.idBrandSetup);
    }else{
        this.getAllWebsiteList();
    }

    if(this.userLogin){
        this.getAllSujectList();
        this.getAllDepartmentList();
        this.getStatusList();
        this.getRelevanceList();
        this.getHelpTicketList(0);
        this.getShortCutsByDepartment();
        if(this.formType == 4 ){
        //   this.getAllUsersList();
        }
    }

    sessionStorage.removeItem('TicketSelected');
    sessionStorage.removeItem('IdSiteType');

    if(this._refreshData){
        this.executeIntervalHardRefresh();
    }else{
        this.detenerIntervalHardRefresh();
    }
  }

  ngOnDestroy(): void {
    this.detenerIntervalHardRefresh();
  }

  executeIntervalHardRefresh(){
    clearInterval(this._interval);
    this._interval = setInterval(() => {
        window.location.reload();
    }, 180000);//180000
    this._isActiveInterval = true;
  }

  detenerIntervalHardRefresh(){
    clearInterval(this._interval);
    this._isActiveInterval = false;
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  refreshData($event:any){
    this.getHelpTicketList($event);
  }

  onStepChange(showMessage: boolean){
    this.showMessageInfo = showMessage;
    this.getHelpTicketList(this.tabSelected);
    this._refreshData = false;
    this.detenerIntervalHardRefresh();
    if(!showMessage){
      this._refreshData = true;
      this.executeIntervalHardRefresh();
      this.UpdateOpenTicketStatus(this.showMessageInfo);
    }
  }

  onBackBtnEvent(event:any){
    //this.onStepChange(event);
    this.getHelpTicketList(this.tabSelected);
  }

  getHelpTicketList(tabSelected:any) {
    if(!this._isActiveInterval && tabSelected == 0 || !this._isActiveInterval && tabSelected == 1){
      if(!this._isActiveInterval){
        this.executeIntervalHardRefresh();
      }
    }
    if(tabSelected == 2 || tabSelected == 1 && this.thirdTab =='USER.DIRECT_TICKET_CENTER.THIRD_TAB'){//help, partner, internal
      if(this._isActiveInterval){
        //detener el interval cuando se esta creando un ticket
        this.detenerIntervalHardRefresh(); 
      } 
    }
    this.tabSelected = tabSelected;
    this.payloadData = [];
    const data = {
      idUser: this.userLogin.idUser,
      transactionType: 0,
      idSiteType: this.ticketCenterType,
    }

    if(tabSelected<= 1){
      const URL = `${environment.WebApiUrl + this.OntabSelected(tabSelected) }`;
        this.infoData = [];
      this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
          if(result.success){
            this.payloadData = result.data;

            this.payloadData.forEach((d:any) => {
              d.date = new Date(d.ticketInfo.dateTransaction);
              if(d.ticketInfo.department.length > 0){
                d.departmentName = d.ticketInfo.department[0].departmentName;
              }else{
                d.departmentName = 0
              }

              if(this._BrandSetup != null && this.domainInfo.idSite == 992){
                const info = [
                    d.ticketInfo.ticketNumber,
                    d.ticketInfo.idPlayer,
                    d.departmentName,
                    d.subject,
                    d.ticketInfo.subjectField,
                    d.relevance,
                    d.status,
                    d.ticketInfo.assignedUser,
                    transformMediumDate(d.ticketInfo.dateTransaction)
                  ];
                  this.infoData.push(info);
              }
              else{
                const info = [
                    d.ticketInfo.ticketNumber,
                    d.ticketInfo.idPlayer,
                    d.site,
                    d.departmentName,
                    d.subject,
                    d.relevance,
                    d.ticketInfo.assignedUser,
                    d.status,

                    transformMediumDate(d.ticketInfo.dateTransaction)
                  ];
                  this.infoData.push(info);
              }
              });
          }else {
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
          this.showHardReloadBtn = true;
          console.log(err);
        }
      });
    }
  }

  getWebsitesBrandSetup(){
    this._listBrandSites.forEach(e => {
        e.value = e.description;
        e.label = e.description;
    });
    this.allSiteTags = this._listBrandSites;
  }

  getAllWebsiteList() {
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.description;
            value.label = value.description;
          });
          this.allSiteTags = result.data;
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllSujectList(){
    this.subjectList = [];
    var URL: string = '';
    if (this.domainInfo != null && this.domainInfo.idSite != 0){
      URL = `${environment.WebApiUrl}${GetMethods.GET_SUBJECTS_BY_CTS_ADMIN_PROFILE}?IdSite=${this.domainInfo.idSite}`;
    }else {
      URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SUBJECTS}`;
    }

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          const data: any = []
          result.data.forEach((value: any) => {
            if (
              (this.formType === 1 && value.isHelp) ||
              (this.formType === 2 && value.isPartner) ||
              (this.formType === 3 && value.isInternal) ||
              (this.formType === 4 && value.isDirect)
            ) {
                if(this.domainInfo.idSite === 0){
                    value.value = value.subjectName;
                    value.label = value.subjectName;
                }
                else{
                    value.value = value.name;
                    value.label = value.name;
                }

              data.push(value);
            }
          });
          this.subjectList = data.sort((a, b) => a.idSubject - b.idSubject);
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllDepartmentList(){

    this.departmentList = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.name;
            value.label = value.name;
          });
          this.departmentList = result.data.filter((x:any) => x.isActive == true);
          if(this._BrandSetup != null){
            const sendToDepartmentIds = [this._BrandSetup.idDepartment, 1, 16]
            this.departmentList = this.departmentList.filter(x => sendToDepartmentIds.includes(x.idDepartment));
          }
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getStatusList(){
    this.statusList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_STATUS_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.description;
            value.label = value.description;
          });
          this.statusList = result.data;
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getRelevanceList(){
    this.relevanceList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_RELEVANCE_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.description;
            value.label = value.description;
          });
          this.relevanceList = result.data;
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getShortCutsByDepartment(){
    this.shortCutByDepartment = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_SHORTCUTS_BY_DEPARTMENT + this.userLogin.idDepartment}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.idDepartment;
            value.label = value.description;
          });
          this.shortCutByDepartment = result.data;
          this.shortCutByDepartment = result.data.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            return nameA.localeCompare(nameB);
          });
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllUsersList(loadingWait){
    this.allUserList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_USERS}`;
    if(!loadingWait){
        this._loadingService.toggleLoading()
    }
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.filter((x: { isActive: boolean; }) => x.isActive === true);
          result.data.forEach((value: any) => {
            value.value = value.IdDepartment;
            value.label = value.Description;
          });
          this.allUserList = result.data;
          if(!loadingWait){
          this._loadingService.toggleLoading()
          }
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }


  OntabSelected(tabSelected: number){
    switch (tabSelected) {
      case 0: return GetMethods.GET_ALL_TICKET_INFO + '?idUser=' + this.userLogin.idUser + '&transactionType=' + 0 + '&IdSiteType=' + this.ticketCenterType;
      case 1:
      if(this.thirdTab =='USER.DIRECT_TICKET_CENTER.THIRD_TAB'){
        this.getAllUsersList(false)
        return GetMethods.GET_MY_TICKETS + '?idUser=' + this.userLogin.idUser + '&transactionType=' + 0 + '&IdSiteType=' + this.ticketCenterType;
      }else{
        return GetMethods.GET_MY_TICKETS + '?idUser=' + this.userLogin.idUser + '&transactionType=' + 0 + '&IdSiteType=' + this.ticketCenterType;
      }


      default: return;
    }
  }

  showDialog() {
    this.visible = (this.payloadData.length == 0)? false: true;
  }

  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme)
  }

  onTicketSelected(event:any){
    this.GetOpenTicketStatus(event);
  }

  TicketSelectedStatus(event: any){
    if(!this.openTicketStatus){
        this.onStepChange(true);
        this.ticketSelected = event;
        this.UpdateOpenTicketStatus(this.showMessageInfo);
    }
  }

  UpdateOpenTicketStatus(showMessageInfo: boolean) {
    let obj: OpenTicketDTO = new OpenTicketDTO();
    obj.IdTicket = this.showMessageInfo ? this.ticketSelected.idTicket : 0;
    obj.openStatus = showMessageInfo;
    obj.User = this.userLogin.idUser;
    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_TICKET_STATUS}`;
    this._apiService.apiPostMethod(URL, obj).subscribe({
      next: (result: ResponseData) => {},
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  GetOpenTicketStatus(ticket:any) {
    let IdTicket = ticket.idTicket;
    const URL = `${environment.WebApiUrl + GetMethods.GET_OPEN_TICKET_STATUS + IdTicket}`;
    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if (result.success) {
                if (result.data.openTicketStatus == false) {
                    this.openTicketStatus = false;
                    this.TicketSelectedStatus(ticket);
                }
                else {
                    this.openTicketStatus = true;
                    this.userActive = result.data.openTicketUser
                    this.getMessageInfo(this._translateService.instant('GENERICS.ALERT_USER_EDIT') +' '+ this.userActive, 3000);
                }
            } else {
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        },
        error: (err: any) => {
            this.loading = false;
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            console.log(err);
        }
        });
    }

    getMessageInfo(message: string, time: number) {
        this.messageResult = message;
        this._notificationService.warning(this.messageResult, 'bg-warning', 'animate__backInUp', 3000);
        setTimeout(() => {
            this.messageResult = '';
        }, time);
    }


    GetBrandSites(idBrandSetup: number) {
        this._apiService.apiGetMethod(`${environment.WebApiUrl}${GetMethods.GET_BRAND_SELECTED_BY_ID_SITE}${idBrandSetup}`).subscribe(data => {
          if(data.success){
            this._listBrandSites = data.data;
            if(this._listBrandSites)
                this.getWebsitesBrandSetup();
            sessionStorage.setItem('BrandSites', JSON.stringify(this._listBrandSites));
            this._ExistsBrandSetup = true;
          }
        });
    }

}
